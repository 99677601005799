body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  color: var(--ssf-gray3);
  background-color: var(--ssf-gray1);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  touch-action: manipulation;
}

.Ordersbottom {
  border-bottom: 1px solid #f9f9f9;
}

.w6 {
  width: 32rem;
}

.w7 {
  width: 48rem;
}

.w8 {
  width: 64rem;
}

.w9 {
  width: 96rem;
}
:root {
  --ssf-gray: rgba(43, 61, 80, 0.2);
  --ssf-gray1: rgba(43, 61, 80, 0.05);
  --ssf-gray2: rgba(43, 61, 80, 0.6);
  --ssf-gray3: rgba(43, 61, 80, 0.8);
  --ssf-gray4: rgba(43, 61, 80, 1);

  --ssf-blue: #2d9bd9;
  --ssf-blue-light: hsl(210, 72%, 72%);
  --ssf-green: #359130;
  --ssf-green-light: #7abd76;
  --ssf-red: #ab5459;
  --ssf-red-light: #e30613;
  --ssf-orange: #fbae17;
  --ssf-purple: #b77dc1;
  --ssf-pink: #e9046c;
}

.bg-primary {
  background-color: var(--ssf-blue);
}
.bg-primary-dark {
  background-color: #e9046c;
}

.hover-bg-primary:focus,
.hover-bg-primary:hover {
  background: hsl(210, 72%, 24%);
}

.hover-bg-ssf-red:focus,
.hover-bg-ssf-red:hover {
  background-color: #923b40;
}
.hover-bg-ssf-gray2:focus,
.hover-bg-ssf-gray2:hover {
  background-color: var(--ssf-gray2);
}
.hover-bg-ssf-gray3:focus,
.hover-bg-ssf-gray3:hover {
  background-color: var(--ssf-gray3);
}

/* Modal View */
.customModal {
  box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px;
  height: 60%;
  width: auto;
  backdrop-filter: blur(5px) !important;
  background: #f2f3f5 !important;
  color: #555555 !important;
  z-index: 10000;
}

.customModalPDF {
  box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px;
  height: 80%;
  width: auto;
  backdrop-filter: blur(5px) !important;
  background: #f2f3f5 !important;
  color: #555555 !important;
  z-index: 10000;
}

/* FONT COLORS */
.ssf-gray1 {
  color: var(--ssf-gray1);
}
.ssf-gray2 {
  color: var(--ssf-gray2);
}
.ssf-gray3 {
  color: var(--ssf-gray3);
}
.ssf-gray4 {
  color: var(--ssf-gray4);
}
.ssf-blue {
  color: var(--ssf-blue);
}
.ssf-blue-light {
  color: var(--ssf-blue-light);
}
.ssf-green {
  color: var(--ssf-green);
}
.ssf-green-light {
  color: var(--ssf-green-light);
}
.ssf-red {
  color: var(--ssf-red);
}
.ssf-red-light {
  color: var(--ssf-red-light);
}
.ssf-orange {
  color: var(--ssf-orange);
}
.ssf-purple {
  color: var(--ssf-purple);
}

.hover-ssf-gray1:hover {
  color: var(--ssf-gray1);
}
.hover-ssf-gray2:hover {
  color: var(--ssf-gray2);
}
.hover-ssf-gray3:hover {
  color: var(--ssf-gray3);
}
.hover-ssf-gray4:hover {
  color: var(--ssf-gray4);
}

/* BACKGROUND COLORS */
.bg-ssf-gray1 {
  background-color: var(--ssf-gray1);
}
.bg-ssf-gray2 {
  background-color: var(--ssf-gray2);
}
.bg-ssf-gray3 {
  background-color: var(--ssf-gray3);
}
.bg-ssf-gray4 {
  background-color: var(--ssf-gray4);
}
.bg-ssf-blue {
  background-color: var(--ssf-blue);
}
.bg-ssf-blue-light {
  background-color: var(--ssf-blue-light);
}
.bg-ssf-green {
  background-color: var(--ssf-green);
}
.bg-ssf-green-light {
  background-color: var(--ssf-green-light);
}
.bg-ssf-red {
  background-color: var(--ssf-red);
}
.bg-ssf-red-light {
  background-color: var(--ssf-red-light);
}
.bg-ssf-orange {
  background-color: var(--ssf-orange);
}
.bg-ssf-purple {
  background-color: var(--ssf-purple);
}
.bg-ssf-pink {
  background-color: var(--ssf-pink);
}

/* HOVER BACKGROUND COLORS */
.hover-bg-ssf-gray1:hover {
  background-color: var(--ssf-gray1);
}
.hover-bg-ssf-gray2:hover {
  background-color: var(--ssf-gray2);
}
.hover-bg-ssf-gray3:hover {
  background-color: var(--ssf-gray3);
}
.hover-bg-ssf-gray4:hover {
  background-color: var(--ssf-gray4);
}

/* BORDER COLORS */
.b--ssf-gray1 {
  border-color: var(--ssf-gray1);
}
.b--ssf-gray2 {
  border-color: var(--ssf-gray2);
}
.b--ssf-gray3 {
  border-color: var(--ssf-gray3);
}
.b--ssf-gray4 {
  border-color: var(--ssf-gray4);
}
.b--ssf-blue {
  border-color: var(--ssf-blue);
}
.b--ssf-blue-light {
  border-color: var(--ssf-blue-light);
}
.b--ssf-green {
  border-color: var(--ssf-green);
}
.b--ssf-green-light {
  border-color: var(--ssf-green-light);
}
.b--ssf-red {
  border-color: var(--ssf-red);
}
.b--ssf-red-light {
  border-color: var(--ssf-red-light);
}
.b--ssf-orange {
  border-color: var(--ssf-orange);
}
.b--ssf-purple {
  border-color: var(--ssf-purple);
}
.b--ssf-json-red {
  border: 1px solid var(--ssf-red-light) !important;
  outline: none !important;
}
.b--ssf-json-green {
  border: 1px solid var(--ssf-green) !important;
  outline: none !important;
}

.box-shadow-ssf-gray1 {
  box-shadow: 0 1px 2px 0 var(--ssf-gray1);
}
.box-shadow-ssf-gray2 {
  box-shadow: 0 1px 2px 0 var(--ssf-gray2);
}
.box-shadow-ssf-gray3 {
  box-shadow: 0 1px 2px 0 var(--ssf-gray3);
}
.box-shadow-ssf-gray4 {
  box-shadow: 0 1px 2px 0 var(--ssf-gray4);
}
.box-shadow-ssf-blue {
  box-shadow: 0 1px 2px 0 var(--ssf-blue);
}
.box-shadow-ssf-orange {
  box-shadow: 0 1px 2px 0 var(--ssf-orange);
}
.box-shadow-ssf-green {
  box-shadow: 0 1px 2px 0 var(--ssf-green);
}
.box-shadow-ssf-red {
  box-shadow: 0 1px 2px 0 var(--ssf-red);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.f0 {
  font-size: 0;
}

.lh-0 {
  line-height: 0;
}

.basis-0 {
  flex-basis: 0;
}

.mix-multiply {
  mix-blend-mode: multiply;
}

.fit-contain {
  object-fit: contain;
}
.fit-cover {
  object-fit: cover;
}

.table {
  display: flex;
  width: 100%;
  justify-content: left;
}

.table-cell {
  padding: 5px;
  display: flex;
  align-items: left;
  background-color: #e0e0e0;
}

.table-cell-big {
  width: 500px;
}

.table-cell-middle {
  width: 250px;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@media screen and (max-width: 1000) {
  .Charts {
    display: none;
  }

  .CycleTime {
    width: 100%;
  }
}

.shadow-6 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

/*

  WORD BREAK

*/
.word-normal {
  word-break: normal;
}
.word-wrap {
  word-break: break-all;
}
.word-nowrap {
  word-break: keep-all;
}

@media screen and (min-width: 30em) {
  .word-normal-ns {
    word-break: normal;
  }
  .word-wrap-ns {
    word-break: break-all;
  }
  .word-nowrap-ns {
    word-break: keep-all;
  }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
  .word-normal-m {
    word-break: normal;
  }
  .word-wrap-m {
    word-break: break-all;
  }
  .word-nowrap-m {
    word-break: keep-all;
  }
}

@media screen and (min-width: 60em) {
  .word-normal-l {
    word-break: normal;
  }
  .word-wrap-l {
    word-break: break-all;
  }
  .word-nowrap-l {
    word-break: keep-all;
  }
}

/* Remove scroll on the body when react-modal is open */
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f4;
}

.ReactModal__Content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
}

.ReactVirtualized__Table__row:nth-child(even) {
  background-color: rgba(116, 116, 116, 0.1);
}

.shopfloor-navbar {
  box-shadow: 0px 2px 5px -1px var(--ssf-gray2);
}

.list-item,
.list-item:link,
.list-item:visited {
  color: var(--ssf-gray4);
  background-color: white;
  box-shadow: 0 1px 2px 0 var(--ssf-gray2);

  text-decoration: none;
  transition: color 0.15s ease-in, background-color 0.15s ease-in;
}

.list-item:hover,
.list-item:active,
.list-item:focus {
  background-color: var(--ssf-gray1);
}
.list-item:focus {
  outline: 1px dotted currentColor;
}

.drop-up .Select-menu-outer {
  top: auto;
  bottom: 100%;
  margin-top: 0px;
  margin-bottom: -1px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: none;
  width: auto;
}

.drop-up.is-open > .Select-control {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.drop-up .rdtPicker {
  top: auto;
  bottom: 100%;
}

.wh48 {
  width: 32px;
  height: 32px;
}

@media screen and (min-width: 60em) {
  .wh48 {
    width: 48px;
    height: 48px;
  }
}

svg {
  height: auto;
  width: auto;
}
